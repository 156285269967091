import React from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import Logo from '../assets/images/hp-logo.png';
import breakpoints from "../styles/breakpoints";

const Section = styled.section`
  position: absolute;
  top: 0;
  bottom: 0;
  background: var(--white);
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

const Content = styled.div`
  padding: 1.75rem 0;
  width: 100%;

  @media only screen and (min-width: 600px) {
    max-width: 35rem;
  }
`;

const Copy = styled.div`
  padding: 0 1.25rem;
`;

const Title = styled.h2`
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
  text-align: center;

  span {
    font-size: 2rem;
    color: var(--secondary);
  }

  a {
    text-decoration: none;
    color: var(--black);
  }
`;

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15rem 0 0;
  padding: 0 1.25rem;

  svg {
    height: 50px;

    &.laterooms {
      height: 25px;
      width: 100%;
    }
  }

  @media only screen and (min-width: 600px) {
    flex-direction: row;

    svg.laterooms {
        margin-left: 40px;
    }
  }
`;

const LogoImage = styled.img`
  width: auto;
  height: 4rem;
  object-fit: contain;
`;


const Loader = styled.div`
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    transform: translate(-50%,-50%);
  
  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  
  div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--primary);
    margin: -4px 0 0 -4px;
  }
  
  div:nth-child(1) {
    animation-delay: -0.036s;
  }
  
  div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  
  div:nth-child(2) {
    animation-delay: -0.072s;
  }
  
  div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  
  div:nth-child(3) {
    animation-delay: -0.108s;
  }
  
  div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  
  div:nth-child(4) {
    animation-delay: -0.144s;
  }
  
  div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  
  div:nth-child(5) {
    animation-delay: -0.18s;
  }
  
  div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  
  div:nth-child(6) {
    animation-delay: -0.216s;
  }
  
  div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  
  div:nth-child(7) {
    animation-delay: -0.252s;
  }
  
  div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  
  div:nth-child(8) {
    animation-delay: -0.288s;
  }
  
  div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media(min-width: ${breakpoints.md}){
    
    transform: translate(-50%,-50%) scale(1.4);
    
  }
`;

export default function RedirectPage({data}) {
    let finalUrl;

    const parameters = {};
    let region = '';
    let checkInDate = '';
    let checkOutDate = '';
    let snaptrip = "true";
    let parkName;


    if (typeof location !== `undefined`) {
        finalUrl = location.href.includes('url=') ? location.href.split('url=')[1] : false;

        if (finalUrl) {
          parkName = finalUrl.includes('name=') ? finalUrl.split('name=')[1] : false
          finalUrl = finalUrl.includes('&name=') ? finalUrl.split('&name=')[0] : finalUrl

          checkInDate = parameters['check_in_on'] ? new Date(Date.parse(parameters['check_in_on'])): null;

          checkOutDate = checkInDate ? new Date(checkInDate) : null;

          checkInDate = checkInDate ? checkInDate.toLocaleDateString('en-GB') : null;

          let minimumNights = parseInt(parameters['minimum_nights']);

          checkOutDate ? checkOutDate.setDate(checkOutDate.getDate() + minimumNights) : null;

          checkOutDate = checkOutDate ? checkOutDate.toLocaleDateString('en-GB'): null;

          region = parameters['region'] ? parameters['region'].split(',')[0] : null;

          snaptrip = finalUrl.includes('snaptrip'); 
        }

    }

    setTimeout(function () {
        if (finalUrl && typeof window !== `undefined`) {
            window.location.href = finalUrl;
        } else if (!finalUrl) {
          window.location.href = window.location.origin;
        }
    }, 3000);

    return (
        <Section>
            <Content>
                <Copy>
                    {parkName && <Title>
                      We are now redirecting you to: <br />
                      <span>{decodeURI(parkName)}</span> 
                    </Title>}
                </Copy>
                <Loader className="lds-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </Loader>
                <Logos>
                    <a href="https://www.holidayparks.com/" target="_blank">
                        <LogoImage alt="Holiday Parks logo" src={Logo}/>
                    </a>
                </Logos>
      
            </Content>
        </Section>
    );
}

export const query = graphql`
	query redirectQuery {
		regions: allSanityRegion {
			nodes {
				name
				id
				slug {
					current
				}
        showInNav
			}
		}
		settings: sanitySiteSettings(_id: {eq: "siteSettings"})  {
			description
			id
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
				fixed(height: 600, width: 1200) {
					src
				}
				}
			}
		}
	}
`;